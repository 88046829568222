import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";
import { B2bI18nService } from "../../../projects/b2b-ecommerce-lib/src/lib/services/b2b-translate.service";

@Injectable({
  providedIn: "root",
})
export class I18nService extends B2bI18nService {}

// export function appInitializerFactory(translate: TranslateService) {
//   return () => {
//     translate.setDefaultLang('it');
//     return translate.use('it').toPromise();
//   };
// }
